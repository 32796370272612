import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'
import List from '../components/Common/List'
import { useTranslations } from '../hooks/use-translations'

import * as s from '../pages/news.module.scss'

const DocumentManagementPage: React.FC = () => {
  const { getPath } = useTranslations()
  return (
    <>
      <SEO
        title="Construction Document Management"
        description={`SIGNAX's EDMS, a part of ERP, offers a comprehensive ecosystem to manage the construction process lifecycle. From design solution checks to BIM element verification, "as-built" valuation, and investor smartphone reporting, it streamlines the entire process.`}
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          Construction Document Management
        </Typography>
        <Typography variant="body1">
          SIGNAX Electronic Document Management System (EDMS) as a part of
          <Link
            to={getPath('/erp-software-system/')}
            className="has-text-primary"
          >
            {` Enterprise Resources Planning (ERP) `}
          </Link>
          is a completed ecosystem to manage lifecycle of construction process.
          Its opportunities start from checking of the Design solutions
          according to proper classification, cover verification of BIM
          elements, involve «as-build» valuation, reporting in investor’s
          smartphone and more.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/document-management/image-1.png"
            alt="Construction Document Management 1"
            title="Construction Document Management 1"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1" size={16}>
          Signax CDE & Document Management for Construction:
        </Typography>
        <Typography variant="body1" size={16}>
          {`Design solutions - BIM classification – Quantity take-off – Cost Estimating -\n Tendering & Contracting – Drawings QR-verification - As-Build 3D model - As-build 360*Valuation - Quantity & Quality Check-Up –\n Scopes, Budget Execution and Time-Line Reporting`}
        </Typography>
        <Typography variant="h2" color="blue">
          What are the main features of construction document management?
        </Typography>
        <Typography variant="body1">
          First of all, electronic document management in construction should be
          a data focused instead of doc-flow classic system. eDMS operates with
          3D models, 2D drawings and as-built schemes which consist of physical
          parameters of geometry, volume, weight, cost and specific graphic
          information.
        </Typography>
        <Typography variant="body1">
          The text document and drawings exchange used in classic routed systems
          is now replaced by digital management that operates with data out of
          any BIM/CAD format (RVT, IFC, DWG) in Common Data Environment (CDE)
          collaboration.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/document-management/image-2.png"
            alt="Construction Document Management 2"
            title="Construction Document Management 2"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          In addition, a legal digital signature between construction
          stakeholders and/or QR-code are required in order to perform important
          operations of acceptance and payment valuation for completed works.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/document-management/image-3.png"
            alt="Construction Document Management 3"
            title="Construction Document Management 3"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Finally, one of the most important functions of the construction
          workflow is the accumulation and archiving of all design and
          construction documentation throughout the entire construction period,
          ensuring the safety and backup of the electronic archive and
          transferring the digital twin of the building to the operation stage.
        </Typography>
        <Typography variant="h2" color="blue">
          How document management system improves the construction workflow?
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/solutions/document-management/image-4.png"
            alt="Construction Document Management 4"
            title="Construction Document Management 4"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Construction documentation management system eliminates the situation
          of data losses. As a result, it reduces the risk of altering the
          volumes of actually performed works to a minimum. Financial losses in
          connection with the transfer of incorrect/outdated drawings or orders
          to the construction site also dramatically reduce.
        </Typography>
        <Typography variant="body1">
          Introduction of electronic document management increases the speed of
          construction business processes by decrease of the risk from defects,
          related alterations and the time to eliminate deviations from design.
          The compliance with construction deadlines ultimately affects the
          planned budget.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/document-management/image-5.png"
            alt="Construction Document Management 5"
            title="Construction Document Management 5"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Signax EDMS gives the set of tools for data management and check-up to
          project participants just at construction site. It helps to reduce a
          lot of errors and misunderstandings and to influence the process of
          performing, verification and acceptance of the construction scopes.
          That will eliminate the risk of rework due to a discrepancy between
          the plan and the actual. Finally, it will give the impetuous
          acceleration to the overall construction process.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/document-management/image-6.png"
            alt="Construction Document Management 6"
            title="Construction Document Management 6"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Additional advantages of SIGNAX are wide integration options, the
          capability to work in browser and any mobile devices, the ability to
          view 3D data on a smartphone.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/document-management/image-7.png"
            alt="Construction Document Management 7"
            title="Construction Document Management 7"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Another important feature of the construction workflow is necessity of
          multi-user access to Design materials, BOQ tables, Check- and
          Punch-lists, MoMs, Certificates and Reports.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/document-management/image-8.png"
            alt="Construction Document Management 8"
            title="Construction Document Management 8"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Construction process is the exchange of tasks between a large numbers
          of participants. Electronic document flow allows to optimize the
          processes of transferring and approval of documents and drawings
          between the Designer, the Developer, the Main Contractor,
          Subcontractors and the Consultants.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/document-management/image-9.png"
            alt="Construction Document Management 9"
            title="Construction Document Management 9"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          The common data environment allows to instantly find the necessary
          documents, check their status and provide them to project
          participants.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/solutions/document-management/image-10.png"
            alt="Construction Document Management 10"
            title="Construction Document Management 10"
            placeholder="blurred"
          />
        </div>
        <Typography variant="h2" color="blue">
          What is the solution?
        </Typography>
        <Typography variant="body1">
          SIGNAX is a set of IT solutions developed specially for processing,
          working and collaboration with 3D model and 2D graphics information
          directly on the construction site. The use of SIGNAX products makes it
          possible for stakeholders to perform the collaboration in Common Data
          Environment in direct connection with BIM:
        </Typography>
        <List className="pl-2">
          <li>Form, check & transfer BIM-model to site;</li>
          <li>
            Make precise quantity take-offs from BIM and transfer it to
            participants via BIM-link technology;
          </li>
          <li>
            Provide task operation, as-build check-up, analysis & forecasting
            project management tools;
          </li>
          <li>
            Collect construction site real-time data in as-built BIM model; make
            its comparison with actual data by panoramic photos job log;
          </li>
          <li>
            Track, oversight and provide 360° visibility of construction
            progress reporting in smartphone dashboard.
          </li>
        </List>
        <div className={s.imageWrap}>
          <StaticImage
            src="../assets/images/solutions/document-management/image-11.png"
            alt="Construction Document Management 11"
            title="Construction Document Management 11"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          The solution based on the SIGNAX platform is easy to start for users
          and does not require any special software to be installed on their
          computers. It also has relatively low costs for the purchase and
          configuration. The implementation of SIGNAX EDMS helps the company to
          achieve its goals in a short time!
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default DocumentManagementPage
